<template>
  <div v-if="!showModal">
    <a-spin :spinning="loading">
      <a-form ref="form" :model="formState" name="form" @finish="onSearch">
        <!-- 搜索栏 -->
        <a-row>
          <a-form-item label="券名称" name="title" class="ui-form__item">
            <a-input v-model:value="formState.title" placeholder="请输入券名称"></a-input>
          </a-form-item>

          <a-form-item label="券类型" name="couponType" class="ui-form__item">
            <a-select v-model:value="formState.couponType" placeholder="请选择券类型" allow-clear style="width: 180px;">
              <a-select-option :value="1">代金券</a-select-option>
              <a-select-option :value="2">兑换券</a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item label="生成类型" name="type" class="ui-form__item">
            <a-select v-model:value="formState.type" placeholder="请选择生成类型" allow-clear style="width: 180px;">
              <a-select-option :value="1">批量生成券</a-select-option>
              <a-select-option :value="2">活动券</a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item label="券用途" name="useLimit" class="ui-form__item">
            <a-select v-model:value="formState.useLimit" placeholder="请选择券用途" allow-clear style="width: 180px;">
              <a-select-option :value="1">影片</a-select-option>
              <a-select-option :value="2">演出</a-select-option>
              <a-select-option :value="3">卖品</a-select-option>
              <a-select-option :value="4">商城</a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item label="状态" name="isDisabled" class="ui-form__item">
            <a-select v-model:value="formState.isDisabled" placeholder="请选择状态" allow-clear style="width: 180px;">
              <a-select-option :value="0">启用</a-select-option>
              <a-select-option :value="1">禁用</a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item label="领取结束时间" name="endTime" class="ui-form__item">
            <a-range-picker v-model:value="formState.endTime"></a-range-picker>
          </a-form-item>

          <a-form-item label="生成时间" name="createTime" class="ui-form__item">
            <a-range-picker v-model:value="formState.createTime"></a-range-picker>
          </a-form-item>
        </a-row>

        <a-row>
          <a-col :span="24" style="text-align: right">
            <a-button
                style="margin-right: 10px"
                type="primary"
                html-type="submit"
            >搜索</a-button>
            <a-button @click="reset">重置</a-button>
          </a-col>
        </a-row>

      </a-form>

      <div style="margin-top: 20px;">
        <a-table :pagination="pagination" :columns="columns" :dataSource="list" rowKey="id"
                 :scroll="{ x: 2000 }">
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'type'">
              <div v-if="record.type === 1">批量生成券</div>
              <div v-if="record.type === 2">活动券</div>
            </template>
            <template v-if="column.key === 'couponType'">
              <div v-if="record.couponType === 1">代金券</div>
              <div v-if="record.couponType === 2">兑换券</div>
            </template>
            <template v-if="column.key === 'useLimit'">
              <div v-if="record.useLimit === 1">影片</div>
              <div v-if="record.useLimit === 2">演出</div>
              <div v-if="record.useLimit === 3">卖品</div>
              <div v-if="record.useLimit === 4">商城</div>
            </template>
            <template v-if="column.key === 'endTime'">
              {{ transDateTime(record.startTime, 1) }} - {{ transDateTime(record.endTime, 1) }}
            </template>
            <template v-if="column.key === 'endNum'">
              {{ record.totalNum - record.stockNum }}
            </template>
            <template v-if="column.key === 'createTime'">
              {{ transDateTime(record.createTime, 1) }}
            </template>
            <template v-if="column.key === 'isDisabled'">
              <a-tag v-if="!record.isDisabled" color="#87d068">启用</a-tag>
              <a-tag v-if="record.isDisabled" color="#f50">禁用</a-tag>
            </template>
            <template v-if="column.key === 'action'">
              <a-dropdown :trigger="['click', 'hover']">
                <a-button>操作 <Icon icon="DownOutlined"></Icon>
                </a-button>
                <template #overlay>
                  <a-menu>
                    <div v-permission="['coupon_snack_record_detail']" v-if="record.type === 1" @click="onDetail(record)">
                      <a-menu-item>
                        详情
                      </a-menu-item>
                    </div>
                    <div v-permission="['coupon_snack_record_edit']" v-if="record.type === 2" @click="onEdit(record)">
                      <a-menu-item>
                        编辑
                      </a-menu-item>
                    </div>
                    <div v-permission="['coupon_snack_record_open']" v-if="record.isDisabled" @click="onDisabled(record)">
                      <a-menu-item>
                        启用
                      </a-menu-item>
                    </div>
                    <div v-permission="['coupon_snack_record_close']" v-else @click="onDisabled(record)">
                      <a-menu-item>
                        禁用
                      </a-menu-item>
                    </div>
                    <div v-permission="['coupon_snack_record_increase']" v-if="record.type === 2" @click="onAddNum(record)">
                      <a-menu-item>
                        增加数量
                      </a-menu-item>
                    </div>
                    <div v-permission="['coupon_snack_record_activityCode']" v-if="record.type === 2" @click="onSeeCode(record)">
                      <a-menu-item>
                        查看活动码
                      </a-menu-item>
                    </div>
                    <div v-permission="['coupon_snack_record_export']" v-if="record.type === 1">
                      <a-menu-item>
                        <exportComponent type="appCouponArchiveCodeExportStrategy" :searchData="{ recordId: record.id }">
                          <div>导出</div>
                        </exportComponent>
                      </a-menu-item>
                    </div>
                  </a-menu>
                </template>
              </a-dropdown>
            </template>
          </template>
        </a-table>
      </div>

      <a-modal v-model:visible="showEdit" title="编辑" width="500px">
        <a-spin :spinning="loading">
          <a-form scrollToFirstError ref="editForm" :model="editModelRef" name="editForm" :labelCol="{span: 8}" :wrapperCol="{span: 16}">
            <a-form-item label="领取截止日期" name="endDate">
              <a-range-picker v-model:value="editModelRef.date"></a-range-picker>
<!--              <a-date-picker v-model:value="editModelRef.endDate"></a-date-picker>-->
            </a-form-item>

            <a-form-item label="单用户领取上限" name="userLimitNum">
              <a-input-number style="width: 200px;" v-model:value="editModelRef.userLimitNum" placeholder="请输入单用户领取上限"></a-input-number>
            </a-form-item>

            <a-form-item label="每日可领取上限" name="dayLimitNum">
              <a-input-number style="width: 200px;" v-model:value="editModelRef.dayLimitNum" placeholder="请输入每日可领取上限"></a-input-number>
            </a-form-item>

            <a-form-item label="是否启用" name="isDisabled">
              <a-switch v-model:checked="editModelRef.isDisabled" checked-children="启用" un-checked-children="禁用" />
            </a-form-item>

            <a-form-item label="图片" :rules="[{ required: true, message: '必填项不允许为空' }]">
              <a-upload
                  v-model:file-list="fileList"
                  list-type="picture-card"
                  action="/admin/ajaxUpload.do"
                  accept="image/*"
                  @change="handleChange"
              >
                <div v-if="fileList.length < 1">
                  <Icon icon="PlusOutlined"></Icon>
                  <div style="margin-top: 8px">Upload</div>
                </div>
              </a-upload>
            </a-form-item>
          </a-form>
        </a-spin>
        <template #footer>
          <a-button @click="showEdit = false">取消</a-button>
          <a-button type="primary" @click="onEditOk">确定</a-button>
        </template>
      </a-modal>

      <a-modal v-model:visible="showAddNum" title="增加数量" width="500px" @ok="onAddNumOk">
        <a-spin :spinning="loading">
          <a-form scrollToFirstError :model="addNumModelRef" name="addForm" :labelCol="{span: 8}" :wrapperCol="{span: 16}">
            <a-form-item label="当前剩余数量" name="totalNum">
              {{ addNumModelRef.totalNum }}
            </a-form-item>

            <a-form-item label="增加数量" name="addStockNum" :rules="[{ required: true, message: '必填项不允许为空' }]">
              <a-input-number style="width: 200px;" v-model:value="addNumModelRef.addStockNum" placeholder="请输入增加数量"></a-input-number>
            </a-form-item>

            <a-form-item label="增加后剩余数量" name="addBeforeNum">
              {{ addNumModelRef.addStockNum ? addNumModelRef.addStockNum + addNumModelRef.totalNum : addNumModelRef.totalNum }}
            </a-form-item>
          </a-form>
        </a-spin>
      </a-modal>

      <a-modal v-model:visible="showShare" width="600px" title="活动码">
        <template #footer>
          <a-button type="primary" @click="showShare = false">关闭</a-button>
        </template>
        <div style="text-align: center;">
          <a-image :src="imageUrl" style="width: 300px;height: 300px;"></a-image>
          <p style="margin-top: 40px;">点击鼠标右键保存二维码</p>
        </div>
      </a-modal>
    </a-spin>
  </div>
  <temp v-if="showModal" :id="id" @back="onBack(false)"></temp>
</template>

<script>
import temp from './temp.vue'
import {
  Icon
} from '@/components/icon/icon.js';
import {
  appCouponGeneratorRecordAddStockNum,
  appCouponGeneratorRecordUpdateStatus,
  getAppCouponGeneratorRecordList, updateAppCouponGeneratorRecord
} from "../../../../service/modules/coupon";
import exportComponent from '@/components/exportReport/exportReport.vue';
export default {
  components: {
    Icon,
    temp,
    exportComponent,
  },
  data() {
    return {
      imageUrl: '',
      showShare: false,
      showModal: false,
      isEdit: false,
      isSee: false,
      id: 0,
      loading: false,
      showEdit: false,
      showAddNum: false,
      rowSelectionType: 'checkbox',
      fileList: [],
      selectedRowKeys: [],
      editModelRef: {},
      addNumModelRef: {},
      searchData: {},
      formState: {},
      columns: [{
        title: '券名称',
        dataIndex: 'title',
      }, {
        title: '生成类型',
        key: 'type',
      }, {
        title: '券类型',
        key: 'couponType',
		width: 100
      }, {
        title: '券用途',
        key: 'useLimit',
		width: 100
      }, {
		  title: '单用户领取上限',
		  dataIndex: 'userLimitNum'
	  }, {
		  title: '每日可领取上限',
		  dataIndex: 'dayLimitNum'
	  }, {
        title: '领取时间',
        key: 'endTime',
      }, {
        title: '可生成数量',
        dataIndex: 'totalNum',
		width: 120
      }, {
        title: '已领取数量',
        key: 'endNum',
		width: 120
      }, {
        title: '剩余数量',
        dataIndex: 'stockNum',
		width: 100
      }, {
        title: '生成时间',
        key: 'createTime',
      }, {
        title: '状态',
        key: 'isDisabled',
		width: 100
      }, {
        title: '操作人',
        dataIndex: 'createUserName',
      }, {
        title: '操作',
        key: 'action',
        fixed: 'right',
        width: 150
      }],
      list: [],
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        size: "middle",
        pageSizeOptions: ['10','20', '50', '100', '500'],
        showTotal: (total) => {
          return "共 " + total + " 条";
        },
        total: 0,
        current: 1,
        pageSize: 10,
        onChange: (page) => {
          this.pagination.current = page;
          this.getData();
        },
        onShowSizeChange: (page, pageSize) => {
          this.pagination.current = 1;
          this.pagination.pageSize = pageSize;
          this.getData();
        }
      },
    }
  },
  activated() {
    this.getData();
  },
  created() {
    this.getData();
  },
  methods: {
    reset() {
      this.$refs.form.resetFields();
      this.onSearch();
    },
    onSearch() {
      this.pagination.current = 1;
      this.searchData = JSON.parse(JSON.stringify(this.formState));
      this.getData();
    },
    onBack(isRef) {
      this.showModal = false;
      if (isRef) {
        this.$nextTick(() => {
          this.getData();
        })
      }
    },
    async getData() {
      if(this.formState.createTime && this.formState.createTime.length === 2) {
        this.searchData.createStartTime = this.moment(this.formState.createTime[0].startOf('day')).unix();
        this.searchData.createEndTime = this.moment(this.formState.createTime[1].endOf('day')).unix();
      }
      delete this.searchData.createTime;
      if(this.formState.endTime && this.formState.endTime.length === 2) {
        this.searchData.endStartTime = this.moment(this.formState.endTime[0].startOf('day')).unix();
        this.searchData.endEndTime = this.moment(this.formState.endTime[1].endOf('day')).unix();
      }
      delete this.searchData.endTime;
      this.loading = true;
      try {
        let ret = await getAppCouponGeneratorRecordList({
          page: this.pagination.current,
          pageSize: this.pagination.pageSize,
          ...this.searchData
        })
        this.loading = false;
        if(ret.code === 200) {
          this.list = ret.data.list;
          this.pagination.total = ret.data.totalCount;
        }
      } catch(e) {
        this.loading = false;
      }
    },
    onDisabled(item) {
      this.$confirm({
        title: '提示',
        content: '确认' + (item.isDisabled ? '启用' : '禁用') + '该生成记录吗？',
        onOk: async () => {
          this.loading = true;
          try {
            let ret = await appCouponGeneratorRecordUpdateStatus({
              id: item.id,
              isDisabled: item.isDisabled ? 0 : 1
            })
            this.loading = false;
            if(ret.code === 200) {
              this.$message.success('操作成功');
              this.getData();
            }
          } catch(e) {
            this.loading = false;
          }
        }
      })
    },
    onDetail(item) {
      this.showModal = true;
      this.id = item.id;
    },
    onEdit(item) {
      this.editModelRef = {};
      this.showEdit = true;
      this.id = item.id;
      this.editModelRef = JSON.parse(JSON.stringify(item));
      this.editModelRef.date = [this.moment(this.editModelRef.startTime * 1000), this.moment(this.editModelRef.endTime * 1000)]
      // if (this.editModelRef.endTime) {
      //   this.editModelRef.endDate = this.moment(this.editModelRef.endTime * 1000);
      // } else {
      //   this.editModelRef.endDate = undefined;
      // }
      this.editModelRef.isDisabled = item.isDisabled ? false : true;
      this.fileList = item.imageUrl.split(',').map(item=>{
        return {
          url: item,
        }
      })
    },
    async onEditOk() {
      this.$refs.editForm.validateFields().then(async () => {
        if(this.fileList.length === 0) {
          this.$message.error('请选择图片！');
          return;
        }
        let postData = JSON.parse(JSON.stringify(this.editModelRef));
        postData.isDisabled = postData.isDisabled ? 0 : 1;
        if (postData.date.length) {
          postData.startTime = this.moment(postData.startDate).startOf('days').unix();
          postData.endTime = this.moment(postData.endDate).endOf('days').unix();
        }
        delete postData.date;
        // postData.endTime = this.moment(postData.endDate).unix();
		    // postData.endTime = this.moment(postData.endDate).unix();
   //      if(postData.dayType === 1) {
			// postData.endTime = this.moment(postData.endDate).unix();
   //      } else {
   //        postData.endTime = postData.createTime + postData.days * 24 * 3600;
   //      }
        delete postData.days;
        delete postData.endDate;
        postData.id = this.id;
        const imgList = [];
        this.fileList.forEach(item=>{
          imgList.push(item.url);
        });
        postData.imageUrl = imgList.join(',');
        this.loading = true;
        try {
          let ret = await updateAppCouponGeneratorRecord(postData)
          this.loading = false;
          if(ret.code === 200) {
            this.$message.success('操作成功');
            this.getData();
            this.showEdit = false;
          }
        } catch(e) {
          this.loading = false;
        }
      })
    },
    onSelectChange(record, selected) {
      if (this.rowSelectionType === 'checkbox') {
        if (selected) {
          // this.selectedRowData.push(JSON.parse(JSON.stringify(record)));
          this.selectedRowKeys.push(record.id);
        } else {
          this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(record.id), 1);
          // this.selectedRowData = this.selectedRowData.filter(item => {
          // 	return item.id !== record.id;
          // });
        }
      }
    },
    onSelectAll(selected) {
      if (selected) {
        this.peopleList.forEach(item => {
          if (this.selectedRowKeys.indexOf(item.id) === -1) {
            // this.selectedRowData.push(JSON.parse(JSON.stringify(item)));
            this.selectedRowKeys.push(item.id);
          }
        })
      } else {
        this.peopleList.forEach(item => {
          if (this.selectedRowKeys.indexOf(item.id) !== -1) {
            // this.selectedRowData.splice(this.selectedRowKeys.indexOf(item.id), 1);
            this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(item.id), 1);
          }
        })
      }
    },
    onAddNum(item) {
      this.addNumModelRef = {};
      this.id = item.id;
      this.showAddNum = true;
      this.addNumModelRef.totalNum = item.totalNum;
    },
    async onAddNumOk() {
      this.loading = true;
      try {
        let ret = await appCouponGeneratorRecordAddStockNum({
          id: this.id,
          addStockNum: this.addNumModelRef.addStockNum
        })
        this.loading = false;
        if(ret.code === 200) {
          this.$message.success('操作成功');
          this.getData();
          this.showAddNum = false;
        }
      } catch(e) {
        this.loading = false;
      }
    },
    onSeeCode(item) {
      this.imageUrl = item.shareMiniImageUrl;
      this.showShare = true;
    },
    handleChange(info) {
      if (info.file.status === 'done') {
        this.fileList = info.fileList;
        const $fileList = [...this.fileList];
        this.fileList = $fileList.map(file => {
          if (file.response) {
            file.url = file.response.data.imgUrl;
          }
          return file;
        });
      }
    },
  }
}
</script>

<style scoped>
.ui-form__item {
  margin-right: 30px;
}
</style>
